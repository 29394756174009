<template>
  <section id="entity-users-list"
  v-loading.fullscreen.lock="downloadLoading"
  :element-loading-text="loadingText">
    <div class="inner-navbar" v-if="!hideActions">
      <el-row>
        <el-col :lg="16" :md="14" :sm="12" :xs="24">
          <div class="icon-block d-flex align-center">
            <div class="icon-text pl-1">
              <h3 class="table-head-text">
                {{ hideLabel ? "" : relationshipDetails.relationship_title }}
              </h3>
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="10" :sm="12" :xs="24">
          <div class="right-block float-right" v-if="!loading && checkAssignPermission()">
            <assign-entity-reationship-data-widget
              :relationshipDetails="relationshipDetails"
              @entityDataAssigned="fetchParentRelatedChildEntityData"
              :existedData="existedDataIds"
              :unAssignedFieldDetails="unAssignedFieldDetails"
              :childEntityDataById="getChildEntityDataById"
              :totalRecords="total"
              :currentEntityData="currentEntityData"
            ></assign-entity-reationship-data-widget>
          </div>

          <div class="right-block float-right" v-if="!loading && checkAddNewPermission()">
            <add-entity-reationship-data-widget
              :relationshipDetails="relationshipDetails"
              @entityDataAssigned="fetchParentRelatedChildEntityData"
              :existedData="existedDataIds"
              :childEntityDataById="getChildEntityDataById"
              :totalRecords="total"
              :currentEntityData="currentEntityData"
            ></add-entity-reationship-data-widget>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="nextRealationshipDetails && !loading && !hideLabel">
        <el-col :lg="24" :md="24" :sm="24" :xs="24">
          <el-button
            class="right-block float-right mt-10 button-uppercase"
            style="padding: 6px 6px !important"
            size="mini"
            @click="gotoNextStep"
          >
            Next
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div class="top">
      <div class="vue-data-table-default">
        <data-tables-server
          :data="data"
          :total="total"
          :resizable="true"
          border
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @query-change="loadData"
          v-loading="loading"
        >
          <el-scrollbar wrap-style=" max-width: calc(100% - 0px); ">
            <el-table-column v-if="relationshipDetails && 
                relationshipDetails.permissions && 
                relationshipDetails.permissions.total == true"
              :min-width="data.length == numberOfRecords ? '60' : '150'"
              label="S.No"
              fixed="left">
              <template slot-scope="scope">
                <span v-if="scope.$index < numberOfRecords">{{ (pageSize * (currentPage - 1)) + scope.$index + 1 }}</span>
                <span v-else-if="scope.$index == numberOfRecords">Page Total</span>
                <span v-else>Grand Total</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="170"
              v-for="step of getFilterColumList"
              :label="step.label"
              :key="step.template_id + '-' + step.id + '-' + step.label"
            >
              <template slot-scope="scope">
                <div :style="getFieldStyle(step, scope.row)">
                  <div v-if="scope.$index >= numberOfRecords && scope.row.entityData[step.template_id][step.id]" class="pl-15">
                    <span style="font-weight : 500">
                      {{ scope.row.entityData[step.template_id][step.id] }}
                    </span>
                  </div>
                  <div v-else>
                    <div v-if="step && step.type === 'PAY_BUTTON'">
                      {{ getFieldValue(scope.row, step) }}
                    </div>
                    <!-- <div
                    class="pl-15"
                    v-else-if="
                      scope.row.entityData &&
                      scope.row.entityData[step.template_id] &&
                      scope.row.entityData[step.template_id][`${step.id}_code`] &&
                      scope.row.entityData[step.template_id][`${step.id}_code`]
                        .formatInternational
                    "
                  >
                    {{
                      scope.row.entityData[step.template_id][`${step.id}_code`]
                        .formatInternational
                    }}
                  </div>
  
                  <div
                    class="pl-15"
                    v-else-if="
                      scope.row.entityData &&
                      scope.row.entityData[step.template_id] &&
                      scope.row.entityData[step.template_id][
                        `${step.id}_currency`
                      ]
                    "
                  >
                    <span
                      v-if="
                        scope.row.entityData[step.template_id][
                          `${step.id}_currency`
                        ] === 'USD'
                      "
                    >
                      {{
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(scope.row.entityData[step.template_id][step.id])
                      }}</span
                    >
                    <span
                      v-else-if="
                        scope.row.entityData[step.template_id][
                          `${step.id}_currency`
                        ] === 'INR'
                      "
                    >
                      {{
                        Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(scope.row.entityData[step.template_id][step.id])
                      }}</span
                    >
                    <span
                      v-else-if="
                        scope.row.entityData[step.template_id][
                          `${step.id}_currency`
                        ] === 'EUR'
                      "
                    >
                      {{
                        Intl.NumberFormat("en-IE", {
                          style: "currency",
                          currency: "EUR",
                        }).format(parseFloat(num).toFixed(2))
                      }}</span
                    >
                    <span
                      v-else-if="
                        scope.row.entityData[step.template_id][
                          `${step.id}_currency`
                        ] === 'CAD'
                      "
                    >
                      {{
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "CAD",
                        }).format(scope.row.entityData[step.template_id][step.id])
                      }}</span
                    >
                    <span
                      v-else-if="
                        scope.row.entityData[step.template_id][
                          `${step.id}_currency`
                        ] === 'AUD'
                      "
                    >
                      {{
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "AUD",
                        }).format(scope.row.entityData[step.template_id][step.id])
                      }}</span
                    >
                  </div> -->
                  <div v-else-if="step && step.type == 'DATE'">
                    {{ getFieldValue(scope.row, step) | globalDateFormat }}
                  </div>
                  <div v-else-if="step && step.type == 'DATE_TIME'"> 
                      <div>
                        <p style="font-size: 13px">
                          {{
                            getFieldValue(scope.row, step)
                            | globalDateTimeFormat
                          }}
                      </p>
                      </div>
                  </div>
                  <span
                          style="font-size: 13px"
                          v-else-if="step.type == 'MULTI_LINE_TEXT'"
                          v-html="getFieldValue(scope.row, step)"
                        ></span>
                  <div v-else-if="step && step.type == 'FILE'">
                    <div
                      v-if="
                        getFieldValue(scope.row, step) &&
                        getFieldValue(scope.row, step).length &&
                        getFieldValue(scope.row, step).length <= 1
                      "
                    >
                      <el-button
                        type="text"
                        style="margin-top: -14px"
                        @click="downloadFile(getFieldValue(scope.row, step)[0])"
                        class="mb-1"
                      >
                        <p style="font-size: 13px">
                          Download <i class="el-icon-download"></i>
                        </p>
                      </el-button>
                    </div>
                    <div
                    class="d-flex"
                      v-else-if="
                        getFieldValue(scope.row, step) &&
                        getFieldValue(scope.row, step).length &&
                        getFieldValue(scope.row, step).length >= 1
                      "
                    >
                          <p
                            v-for="(file, index) of getFieldValue(
                              scope.row,
                              step
                            )"
                            :key="index"
                          >
                           <el-button
                              type="text"
                              style="margin-top: -10px"
                              v-if="file && file.name && index < limit"
                              @click="
                                downloadFile(getFieldValue(scope.row, step)[0])
                              "
                            >
                              {{ file.name | truncate(10, "...") }}
                              <i class="el-icon-download"></i>
                            </el-button>
                          
                          </p>
                          <el-tag
                            style="cursor: pointer; width: 40px"
                            class="ml-1"
                            :value="getFieldValue(scope.row, step).length"
                            v-if="getFieldValue(scope.row, step).length > 1"
                            @click="downloadDocumentsPopup(scope.row, step)"
                          >
                            +{{ getFieldValue(scope.row, step).length - 1 }}
                          </el-tag>
                    </div>
                    <!-- </p> -->
                  </div>
                  <div v-else-if="step && step.type == 'SIGNATURE'">
                    <img
                      v-if="getFieldValue(scope.row, step)"
                      :src="getFieldValue(scope.row, step)"
                      alt="IMG"
                      width="100"
                      height="60"
                    />
                    <p v-else>-</p>
                  </div>
                  <div class="pl-15" v-else>
                    {{ getFieldValue(scope.row, step) }}
                  </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="180" fixed="right" v-if="!hideActions">
              <template slot-scope="scope">
                <div v-if="scope.$index < numberOfRecords">
                <el-button
                  v-if="checkViewPermission()"
                  icon="el-icon-view"
                  circle
                  @click="goToEntityData(scope.row)"
                ></el-button>
                <el-button 
                  v-if="checkRedirectPermission()"
                  icon="el-icon-top-right" 
                  circle 
                  @click="redirectToEntityData(scope.row)"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  :disabled="!allowedDeleteForFieldRelationship"
                  @click="onDetachEntityData(scope.row)"
                  v-if="checkAssignPermission()"
                ></el-button>
                </div>
              </template>
            </el-table-column>
          </el-scrollbar>
        </data-tables-server>
      </div>
    </div>
    <div>
      <dialog-component
      :modal="false"
      :visible="downloadDocuments"
      :title="'Download Documents'"
      :containerWidth="'35%'"
      :containerHeight="'45%'"
      @before-close="closeDownloadDocuments"
    >
      <!-- <el-row>
        <el-input
          class="search mb-1"
          placeholder="Search"
          v-model="searchDocument"
          clearable
          @input="searchDocumentName"
          prefix-icon="el-icon-search"
        ></el-input>
      </el-row> -->
      <div class="sidemenu">
        <el-table
          border
          :data="documentsData"
          :header-row-class-name="'my-header-row'"
          class="tableSty"
          :show-header="true"
        >
          <el-table-column class="labelSty" label="Document Name">
            <template slot-scope="scope">
              <p style="font-size: 14px" slot="reference">
                {{ scope.row.name }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="Action" width="150">
            <template slot-scope="scope">
              <ul class="action-buttons">
                <li>
                      <button class="btn btn-light btn-sm m-lr-1" type="button">
                        <span
                          class="text-muted"
                          @click="onPreview(scope.row)"
                          title="preview"
                      
                        >
                        <i class="fa fa-eye"></i>
                        </span>
                      </button>
                    </li>
                    <li>
                      <button class="btn btn-light btn-sm m-lr-1" type="button">
                        <span
                          class="text-muted"
                          @click="onDownloadFile(scope.row)"
                          target="_blank"
                          ref="downloadLink">
                          <i class="el-icon-download"></i>
                        </span>
                      </button>
                     </li>
                     </ul>
             
              <!-- <el-button
                @click="onDownloadFile(scope.row)"
                target="_blank"
                ref="downloadLink"
                style="width: 120px; font-size: 12px"
              >
                Download
                <i class="el-icon-download"></i>
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer">
        <el-button @click="downloadDocuments = false"> Close </el-button>
      </span>
    </dialog-component>
    </div>
    <complete-entity-data-popup
    v-if="showRelationshipDataDialog"
    :entityId="relationshipDetails.relationship_id"
    :entityDataId="relationshipDataId"
    :entityRefName="getChildEntityDataById.name"
    :primaryDataValue="getChildEntityDataById.name"
    :isMultiple="false"
    :isTitleVisible="false"
    @close="showRelationshipDataDialog = false">
    </complete-entity-data-popup>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import config from "@/config/app";
import Vue from "vue";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import AssignEntityReationshipDataWidget from "../widgets/AssignEntityReationshipDataWidget.vue";
import AddEntityReationshipDataWidget from "../widgets/AddEntityReationshipDataWidget.vue";
import EntityHelper from "@/mixins/EntitiesHelper";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import moment from "moment";
Vue.use(VueLodash, {
  name: "custom",
  lodash: lodash,
});
export default {
  name: "EntityDataList",
  components: {
    AssignEntityReationshipDataWidget,
    AddEntityReationshipDataWidget,
    CompleteEntityDataPopup: () => import("./completeEntityDataPopup.vue")
  },
  mixins: [EntityHelper, entityRelationshipMixin, CustomDashboardConfig, TemplateBuilderhelper],
  props: ["relationshipDetails", "nextRealationshipDetails", "hideLabel","currentEntityData", "hideActions"],
  data() {
    return {
      filterText: "",
      loading: false,
      total: 0,
      currentPage: 1,
      entityDataId: null,
      pageSize: 0,
      data: [],
      documentsLoading: false,
      firstTemplateId: null,
      primaryField: "",
      primaryKey: "",
      dialogTableVisible: false,
      entity_id: "",
      props: {
        children: "children",
        label: "label",
        disabled: "disabled",
      },

      fieldsListData: [],
      defaultKeys: [],
      selectedTableFields: [],
      filterColumList: [],
      checkedFields: [],

      parentRelatedChildEntityData: {
        child_entity_data_ids: [],
      },
      childEntityDataById: {},
      selectedEntityFields: [],
      selectedEntityFieldsObject : {},
      existedDataIds: [],
      allowedDeleteForFieldRelationship: true,
      removableFieldsKeys: [],
      unAssignedFieldDetails: null,
      isApplicationUserSide: false,
      showEntityDataInPopup : false,
      entityData : null,
      numberOfRecords : 0,
      weekDayMap : {
        'MON' : 'Monday',
        'TUE' : 'Tuesday',
        'WED' : 'Wednesday',
        'THU' : "Thursday",
        'FRI' : 'Friday',
        'SAT' : 'Saturday',
        'SUN' : 'Sunday'
      },
      downloadDocuments: false,
      documentsData: [],
      limit: 1,
      loadingText : "Downloading...",
      downloadLoading : false,
      showRelationshipDataDialog : false,
      relationshipDataId : '',
      currentEntity: {}
    };
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDataById",
      "getChildEntityDataById",
      "getEntityDataByEntityId",
      "getEntityDataUpdateStatus",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityRecordsForTable",
      // "entityPrimaryData",
    ]),
    ...mapGetters("templatesData", [
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDownloadUrl",
      "getDownloadError",
    ]),

    ...mapGetters("entityRelationships", [
      "getEntityRelationshipDataStatus",
      "getEntityRelationshipDataAssignStatus",
      "getEntityRelationshipDataDetachStatus",
      "getParentEntityRelatiponshipData",
    ]),
    ...mapGetters("filters", ["getAllEntityFilters"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    getEntityRoute() {
      return "/entity-execute/" + this.$route.params.entity_id;
    },
    logoUploadUrl() {
      return config.S3_BUCKET_URL;
    },

    getFilterColumList() {
      return this.filterColumList;
    },
  },
  async mounted() {
    this.relationshipDetails['permissions'] = {...{add : true, assign : true, redirect : true, view : true, assign_type : 'default_view'},...this.relationshipDetails['permissions']}
    if (
      this.$route.name == "ApEntityExecute" ||
      this.$route.name == "ApPreviewEntityData"
    ) {
      this.isApplicationUserSide = true;
    }
    let limit = (this.pageSizes && this.pageSizes[0]) || 10;
    this.pageSize = limit;
    this.entity_id = this.$route.params.entity_id;
    if(this.relationshipDetails.relationship_id){
      await this.getNecessaryInfo();

      await this.fetchParentRelatedChildEntityData();
    }
  },

  methods: {
    async downloadFile(data) {
      this.downloadLoading = true;
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError || "Error while downloading",
        });
      }
      this.downloadLoading = false;
    },
    setAllowOne() {
      this.relationshipDetails.allowOne = null;

      if (this.relationshipDetails.representation == "TAB") {
        if (
          (this.relationshipDetails.owner_type == "CHILD" &&
            this.relationshipDetails.parent_relation_type == "ONE") ||
          (this.relationshipDetails.owner_type == "PARENT" &&
            this.relationshipDetails.child_relation_type == "ONE")
        ) {
          this.relationshipDetails.allowOne = true;
        } else {
          this.relationshipDetails.allowOne = false;
        }
      } else {
        if (this.relationshipDetails.parent_relation_type == "MANY") {
          this.relationshipDetails.allowOne = false;
        } else {
          this.relationshipDetails.allowOne = true;
        }
      }
    },

    gotoNextStep() {
      let url = this.getStepEntityRelationshipUrl(
        this.nextRealationshipDetails
      );
      this.$router.push(url);
    },
    assignEntityData() {
      this.$router.push(`/entity-execute/${this.entity_id}`);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },
    getFieldValue(row, step) {
      const field = this.selectedEntityFieldsObject[`${step.template_id}#${step.id}`];
      if (!row.entityData?.[step.template_id]?.[step.id]) {
        if (
          field &&
          field.properties &&
          field.properties.tableDefaultValue &&
          field.properties.tableDefaultValue !== ""
        ) {
          return field?.properties?.tableDefaultValue;
        }
      }
      let checkIsGlobalvariable = this.selectedEntityFields.find(
        (e) => e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.id
      );
      if (
        checkIsGlobalvariable &&
        checkIsGlobalvariable.is_global_variable &&
        checkIsGlobalvariable.global_variable_id
      ) {
        let globalVariable = (this.globalVariablesData || []).find(
          (e) => e._id == checkIsGlobalvariable.global_variable_id
        );
        if (globalVariable) {
          if (
            globalVariable.input_type == "IMAGE" &&
            globalVariable.image_url
          ) {
            return globalVariable.image_url;
          } else if (globalVariable.value) return globalVariable.value;
        }
      }
      if(step.type == 'CHECKBOX'){
        if(row.entityData && step.template_id && row.entityData[step.template_id]){
          switch (row?.entityData?.[step.template_id]?.[step.id]){
            case true:
              if (field && field.properties && field.properties.checkedLabel && field.properties.checkedLabel !== "") {
                return field.properties.checkedLabel
              }
              return true
            case false:
              if (field && field.properties && field.properties.unCheckedLabel && field.properties.unCheckedLabel !== "") {
                return field.properties.unCheckedLabel
              }
              return false
          }
        }
      }
      if(
        field &&
        field.input_type == "PAYMENT_VARIABLE" &&
        row.entityData &&
        row.entityData[step.template_id] &&
        !row.entityData[step.template_id][step.id]&&
        step.type === "CURRENCY"){
          row.entityData[step.template_id][step.id] = 0
        }
      if (step.type == "WEEKDAYS") {
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][step.id]
        ) {
          if (
            checkIsGlobalvariable?.allow_multiple &&
            typeof row.entityData[step.template_id][step.id] == "object" &&
            row.entityData[step.template_id][step.id].length
          ) {
            let mappedArray = row.entityData[step.template_id][step.id].map(
              (e) => this.weekDayMap[e]
            );
            return mappedArray.join(",");
          } else {
            return this.weekDayMap[row.entityData[step.template_id][step.id]];
          }
        }
        return "";
      } else if (step.type == "HTML_CONTENT") {
        let newfd = this.currentEntity.templates.find((el) => {
          if (el.template_id === step.template_id) {
            return el.templateInfo;
          }
        });
        let val = newfd.templateInfo.sections[0].fields.find((el) => {
          let keyVal = el.key.split("#")[1];
          if (keyVal === step.id && el.input_type == "HTML_CONTENT") {
            return el;
          }
        });

        return val.content;
        // return row.entityData[step.template_id][step.id]
      } else if (step.type == "IMAGE") {
        let id = this.generateKeyForField(step.label);
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][id]
        ) {
          return row.entityData[step.template_id][id];
        }
      } else if (
          (step.type == "AGGREGATE_FUNCTION" || 
          step.type == "CURRENCY") &&
          row.entityData[step.template_id][step.id] != null &&
          row.entityData[step.template_id][step.id] != undefined
        ) {
            let field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
            let data = this.getFieldValue1(field,row.entityData[step.template_id][step.id]);
            if(data) {
              return data
            }
            return row.entityData[step.template_id][step.id];            
        } else if (
        step.type == "FILE" &&
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.id]
      ) {
        this.documentsData =
          row.entityData[step.template_id][step.id].files &&
          row.entityData[step.template_id][step.id].files.length
            ? row.entityData[step.template_id][step.id].files
            : row.entityData[step.template_id][step.id];
        return row.entityData[step.template_id][step.id].files &&
          row.entityData[step.template_id][step.id].files.length
          ? row.entityData[step.template_id][step.id].files
          : row.entityData[step.template_id][step.id];
      }  else if (
          step.type == "LIST" &&
          row.entityData[step.template_id][step.id]
        ) {
          let result,
            
          field = this.selectedEntityFieldsObject[`${step.template_id}#${step.id}`]
          if(!field) {
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          }
          if (field?.list_data) {
            let value = field.list_data.find(
              (e) => e.value == row.entityData[step.template_id][step.id]
            );
            if (value && value.name) {
              result = value.name;
            } else {
              result = row.entityData[step.template_id][step.id];
            }
          } else {
            result = row.entityData[step.template_id][step.id];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.id] != null &&
        row.entityData[step.template_id][step.id] != undefined
      ) {
        if(step.type == "FORMULA") {
          if(field.result_type) {
              step.type = field.result_type;
          }
        }
        if (
          typeof row.entityData[step.template_id][step.id] == "number" &&
          this.isFloat(row.entityData[step.template_id][step.id])
        ) {
          return parseFloat(row.entityData[step.template_id][step.id]).toFixed(
            2
          );
        }
        if (step.type == "MULTI_SELECT") {
          let value = row.entityData[step.template_id][step.id]
            ? row.entityData[step.template_id][step.id]
            : "";
          if (value) {
            if (typeof value == "object" && value.length) {
              return value.join(",");
            }
            return value;
          }
          return "";
        } else if (
          step.type == "SELECT" &&
          row.entityData[step.template_id][step.id]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          if (
            field &&
            field.inputType == "SELECT" &&
            field.is_list &&
            field.global_variable_id
          ) {
            let value = field.options.find(
              (e) => e.value == row.entityData[step.template_id][step.id]
            );
            if (value && value.name) {
              result = value.name;
            }
            result = row.entityData[step.template_id][step.id];
          } else {
            result = row.entityData[step.template_id][step.id];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "LIST" &&
          row.entityData[step.template_id][step.id]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          if (field?.list_data) {
            let value = field.list_data.find(
              (e) => e.value == row.entityData[step.template_id][step.id]
            );
            if (value && value.name) {
              result = value.name;
            } else {
              result = row.entityData[step.template_id][step.id];
            }
          } else {
            result = row.entityData[step.template_id][step.id];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "NUMBER" &&
          row.entityData[step.template_id][step.id]
        ) {
          return typeof row.entityData[step.template_id][step.id] == "number"
            ? row.entityData[step.template_id][step.id]
            : "";
        } else if (step.type == "CHECKBOX_GROUP") {
          if (
            row.entityData &&
            step.template_id &&
            row.entityData[step.template_id] &&
            row.entityData[step.template_id][step.id] &&
            typeof row.entityData[step.template_id][step.id] == "object" &&
            row.entityData[step.template_id][step.id].length
          ) {
            return row.entityData[step.template_id][step.id].length
              ? row.entityData[step.template_id][step.id].join(",")
              : "";
          } else {
            return row.entityData[step.template_id][step.id].length
              ? row.entityData[step.template_id][step.id]
              : "-";
          }
        } else if (
          step.type == "TIME_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "HH:mm:ss"
              ) +
              " - " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "HH:mm:ss"
              )
            );
          }
        } else if (
          step.type == "DATE_TIME_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "MM-DD-YYYY hh:mm:ss A"
              ) +
              " To " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "MM-DD-YYYY hh:mm:ss A"
              )
            );
          }
        } else if (
          step.type == "DATE_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "MM-DD-YYYY "
              ) +
              " To " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "MM-DD-YYYY"
              )
            );
          }
        } else if (
          step.type == "CURRENCY" &&
          row.entityData[step.template_id][step.id] != null &&
          row.entityData[step.template_id][step.id] != undefined
        ) {
            let field =this.selectedEntityFieldsObject[`${step.template_id}#${step.id}`];
            let data = this.getFieldValue1(field,row.entityData[step.template_id][step.id]);
            if(data) {
              return data
            }
            return row.entityData[step.template_id][step.id];            
        }
        if (row.entityData[step.template_id][step.id + "/name"]) {
          if (
            typeof row.entityData[step.template_id][step.id + "/name"] ==
              "object" &&
            row.entityData[step.template_id][step.id + "/name"].length
          ) {
            return row.entityData[step.template_id][step.id + "/name"].join(
              ","
            );
          } else if (
            this.checkDate(row.entityData[step.template_id][step.id + "/name"])
          ) {
            const format = this.getDefaultDateFormat
              ? this.getDefaultDateFormat
              : "MM-DD-YYYY";
            return moment(
              row.entityData[step.template_id][step.id + "/name"]
            ).format(format);
          }
          return row.entityData[step.template_id][step.id + "/name"];
        } else {
          return row.entityData[step.template_id][step.id];
        }
      }
      return "";
    },
    checkDate(string) {
      let dateStamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      if (dateStamp.test(string)) {
        return true;
      }
      return false;
    },
    prepareParams() {
      return {
        name: this.getEntityDataById.name,
        description: this.getEntityDataById.description,
        entity_type: this.getEntityDataById.entity_type,
        templates: this.getEntityDataById.templates,
        id: this.getEntityDataById._id,
        primaryFields: this.getEntityDataById.primaryFields,
        settings: this.selectedFieldKeys,
      };
    },
    async update() {
      let checked = this.$refs.tree.getCheckedNodes();

      let selectedEntity = await lodash.filter(checked, function (node) {
        return !node.children;
      });

      let fields = selectedEntity.filter((e) => !e.fields);
      this.selectedFieldKeys = fields.map((field) => field);

      let params = this.prepareParams();
      await this.$store.dispatch("entities/updateEntityById", params);

      if (this.getEntityDataUpdateStatus) {
        this.loading = false;
        this.dialogTableVisible = false;
        this.$notify({
          title: "Success",
          message: "Settings saved",
          type: "success",
        });
      } else {
        this.dialogTableVisible = true;
        this.$notify({
          title: "Error",
          message: "Failed to Save the Settings",
          type: "error",
        });
        this.loading = false;
      }
    },
    checkedNodes(event) {
      console.log("event", event);

      this.updateNodesData(event);
    },
    updateNodesData(event) {
      if (this.selectedTableFields.indexOf(event.id) !== -1) {
        const index = this.selectedTableFields.indexOf(event.id);
        if (index > -1) {
          this.selectedTableFields.splice(index, 1);
        }
        this.filterColumList = this._.filter(
          this.filterColumList,
          function (o) {
            return o.id != event.id;
          }
        );
      } else {
        console.log("else data", event.id);
        this.filterColumList.push(event);
        this.selectedTableFields.push(event.id);
      }
    },

    async fetchParentRelatedChildEntityData() {
      try {
        this.loading = true;

        this.setAllowOne();

        let params = {
          parent_entity_id: this.relationshipDetails.parent_entity_id,
          parent_entity_data_id: this.relationshipDetails.parent_entity_data_id,
          child_entity_id: this.relationshipDetails.child_entity_id,
        };

        if (this.relationshipDetails.owner_type == "CHILD") {
          params = {
            parent_entity_id: this.relationshipDetails.child_entity_id,
            parent_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_id: this.relationshipDetails.parent_entity_id,
          };
        }

        await this.$store.dispatch(
          "entityRelationships/fetchParentEntityRelationshipData",
          params
        );

        this.parentRelatedChildEntityData = {
          ...this.getParentEntityRelatiponshipData,
        };

        if (
          this.parentRelatedChildEntityData &&
          this.parentRelatedChildEntityData.child_entity_data_ids &&
          this.parentRelatedChildEntityData.child_entity_data_ids.length
        ) {
          this.existedDataIds =
            this.parentRelatedChildEntityData.child_entity_data_ids;
          await this.fetchEntitiesDataForTable(
            this.parentRelatedChildEntityData.child_entity_data_ids
          );
        } else {
          this.data = [];
          this.existedDataIds = [];
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.existedDataIds = [];
      }
    },

    async fetchChildEntityData(id) {
      try {
        this.loading = true;
        await this.$store.dispatch("entities/fetchChildEntityById", id);
        this.childEntityDataById = { ...this.getChildEntityDataById };
        this.loading = false;
      } catch (err) {
        console.log("Relationship error:",err);
      }
    },
    async getNecessaryInfo() {
      this.loading = true;
      await this.fetchChildEntityData(this.relationshipDetails.relationship_id);

      if (this.getChildEntityDataById) {
        // console.log(this.getEntityDataById);
        this.fieldsListData = [];
        this.filterColumList = [];
        this.selectedTableFields = [];
        let allFields = [];

        this.getChildEntityDataById.templates.map((data) => {
          let templateFields = [];
          if (
            data.templateInfo &&
            data.templateInfo.sections &&
            data.templateInfo.sections.length
          ) {
            data.templateInfo.sections[0].fields.map((field) => {
              if (this.relationshipDetails.representation == "FIELD") {
                if (
                  field.input_type == "ENTITY" &&
                  this.relationshipDetails.child_entity_id == field.entity_id
                ) {
                  if (field.validations.required) {
                    this.allowedDeleteForFieldRelationship = false;
                  } else {
                    this.allowedDeleteForFieldRelationship = true;
                    this.removableFieldsKeys = [
                      {
                        key: field.key,
                        template_id: data.template_id,
                        value: this.relationshipDetails.parent_entity_data_id,
                      },
                      {
                        key: field.key + "/name",
                        template_id: data.template_id,
                        value: this.entityDataTitle(),
                      },
                    ];

                    this.unAssignedFieldDetails = this.removableFieldsKeys;
                  }
                }
              }
              templateFields.push({
                label: field.label,
                id: field.key,
                template_id: data.template_id,
                type: field.inputType,
                currency_code: field.validations,
                entity_id: field.entity_id,
              });
            });
          }
          allFields.push(...templateFields);

          this.fieldsListData.push({
            label: data.templateInfo.name,
            id: data.templateInfo.slug + "ABC",
            template_id: data.template_id,
            children: [...templateFields],
          });
        });
        // console.log("Total Fields", allFields);
        if (
          this.getChildEntityDataById.settings &&
          this.getChildEntityDataById.settings.length
        ) {
          this.selectedTableFields = [];
          this.filterColumList = this.getChildEntityDataById.settings;
          //map with latest field type
          this.filterColumList = this.mapTableFieldsType(
            this.getChildEntityDataById,
            this.filterColumList
          );
          await this.getChildEntityDataById.settings.map(async (data) => {
            await this.selectedTableFields.push(data.id);
          });
        } else {
          this.getChildEntityDataById.primaryFields.map(async (data) => {
            let result = this._.filter(allFields, function (o) {
              return o.id === data.key;
            });

            if (result && result.length) {
              await this.filterColumList.push(result[0]);

              await this.selectedTableFields.push(data.key);
            }
          });
        }

        this.currentEntity = this.getChildEntityDataById;
        this.selectedEntityFields = [];
        if (this.currentEntity && this.currentEntity.templates) {
          this.currentEntity.templates.forEach((e) => {
            if (e && e.templateInfo && !e.is_repeatable) {
              this.selectedEntityFields = [
                ...this.selectedEntityFields,
                ...this.getTemplateFields(e.templateInfo),
              ];
            }
          });
        }
        this.selectedEntityFieldsObject = {};
        this.selectedEntityFields.map(e => {
          this.selectedEntityFieldsObject[e.key] = e
        })
      }
    },
    getPrimaryName(primaryField, item) {
      let name = "";
      let key =
        primaryField && primaryField["key"] ? primaryField["key"] : null;
      if (key) {
        if (key.includes("#")) {
          key = key.split("#")[1];
        }
        name =
          item &&
          item.entityData &&
          primaryField.template_id &&
          item.entityData[primaryField.template_id] &&
          item.entityData[primaryField.template_id][key]
            ? item.entityData[primaryField.template_id][key + "/name"] ||
              item.entityData[primaryField.template_id][key]
            : "-";
        if (
          !name &&
          item &&
          item.entity_prime_data &&
          item.entity_prime_data[key]
        ) {
          name =
            item.entity_prime_data[key + "/name"] ||
            item.entity_prime_data[key];
        }
        if (
          primaryField?.inputType == "WEEKDAYS" &&
          this.weekDayMap &&
          this.weekDayMap[name]
        ) {
          name = this.weekDayMap[name];
        }
      }
      if (name && typeof name == "object" && name.length) {
        return name.join(",");
      }
      return name;
    },
    entityDataTitle() {
      let name = "";
      if (this.getEntityPrimaryData) {
        let pr =
          this.getEntityDataById &&
          this.getEntityDataById.primaryFields &&
          this.getEntityDataById.primaryFields[0]
            ? this.getEntityDataById.primaryFields[0]
            : null;
        if (pr) {
          let obj = {}, dt = {};
          (this.getEntityPrimaryData.templates_data || []).forEach(te => {
            dt[te.template_id] = te?.template_data_id?.template_data
          });
          obj = {...this.getEntityPrimaryData, ...{['entityData']: dt}};
          name = this.getPrimaryName(pr, obj);
        }
      }
      return name;
    },

    async fetchEntitiesDataForTable(dataIds = []) {
      this.loading = true;
      let filter = null
      if(this.relationshipDetails && this.relationshipDetails.filter && this.relationshipDetails.filter !== ''){
        await this.$store.dispatch("filters/fetchAllEntityFilters", {entityId: this.getChildEntityDataById._id});
        if(this.getAllEntityFilters && this.getAllEntityFilters.length){
          filter = this.getAllEntityFilters.find(e=> e._id.toString() == this.relationshipDetails.filter.toString())
        }
      }
      let filters = filter !== null && filter !== undefined && filter.filters.length ? filter.filters : []
      let params = {
        entity_id: this.getChildEntityDataById._id,
        template_fields_data: this.getChildEntityDataById.templates.map(
          (template) => {
            return {
              template_id: template.template_id,
            };
          }
        ),
        filters: [...filters],
        limit: this.pageSize,
        page: this.currentPage,
        total : this.relationshipDetails && 
                this.relationshipDetails.permissions && 
                this.relationshipDetails.permissions.total ? 
                  this.relationshipDetails.permissions.total : 
                  false
      };

      if (dataIds.length) {
        params.entity_data_ids = dataIds;
      }
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      this.data = [];
      if (this.getEntityRecordsForTable) {
        this.data = this.getEntityRecordsForTable.data;
        this.numberOfRecords = this.data.length
        this.total = this.getEntityRecordsForTable.total
          ? this.getEntityRecordsForTable.total
          : 0;
        if(params.total == true && this.getEntityRecordsForTable.pageTotal !== null && this.getEntityRecordsForTable.grandTotal !== null){
          this.data.push(this.getEntityRecordsForTable.pageTotal)
          this.data.push(this.getEntityRecordsForTable.grandTotal)
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },

    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        if(e.key?.split('#').length == 1){
        e.key = data._id + "#" + e.key;
        }
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
    async onDetachEntityData(row) {
      this.$confirm("Are you sure to remove the data?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.detachEntityData(row);
      });
    },
    async fetchAllEmployee() {
      this.loading = true;
      this.data = [];

      let params = {
        limit: this.pageSize,
        page: this.currentPage,
        entity_data_ids:
          this.parentRelatedChildEntityData.child_entity_data_ids,
      };

      await this.$store.dispatch("entities/fetchEntityDataById", {
        id: this.relationshipDetails.relationship_id,
        params,
      });
      this.loading = false;
      if (this.getEntityDataByEntityId) {
        this.currentPage = parseInt(this.getEntityDataByEntityId.page) || 1;
        this.total = parseInt(this.getEntityDataByEntityId.total) || 0;
        this.getEntityDataByEntityId.data &&
        this.getEntityDataByEntityId.data.length
          ? (this.data = this.getEntityDataByEntityId.data)
          : (this.data = []);
      }
    },

    backToEntities() {
      this.$router.push({ path: "/entity" });
      // console.log("Query", this.$route.query); .options.history.state.back
      // this.$router.go(-1);
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.currentPage = data.page;
        // await this.fetchAllEmployee();
        await this.fetchEntitiesDataForTable(this.parentRelatedChildEntityData.child_entity_data_ids)
      }
    },
    async viewWorkUserData(row) {
      if (row._id) {
        this.entityDataId = row._id;
        this.$router.push(
          `/entity_data_view/${this.entity_id}?dataId=${row._id}`
        );
      }
    },
    async viewEntityDataInPopup(row){
      if(row._id) {
        this.entityData = row
        this.showEntityDataInPopup = true
      }
    },
    async editWorkUserData(row) {
      if (row._id) {
        let path = `/entityData-Edit/${this.entity_id}?dataId=${row._id}`;
        if (this.$route.path !== path) this.$router.push(path);
        // this.$router.push(

        // );
      }
    },
    getEntityName() {
      return this.getEntityDataById?.name;
    },

    async detachEntityData(entityData) {
      let params;

      if (this.relationshipDetails.representation == "TAB") {
        if (this.relationshipDetails.owner_type == "CHILD") {
          params = {
            parent_entity_id: this.relationshipDetails.parent_entity_id,
            child_entity_id: this.relationshipDetails.child_entity_id,
            parent_entity_data_id: entityData._id,
            child_entity_data_ids: [
              this.relationshipDetails.parent_entity_data_id,
            ],
          };
        } else {
          params = {
            parent_entity_id: this.relationshipDetails.parent_entity_id,
            child_entity_id: this.relationshipDetails.child_entity_id,
            parent_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_data_ids: [entityData._id],
          };
        }
      } else {
        params = {
          parent_entity_id: this.relationshipDetails.parent_entity_id,
          child_entity_id: this.relationshipDetails.child_entity_id,
          parent_entity_data_id: entityData._id,
          child_entity_data_ids: [
            this.relationshipDetails.parent_entity_data_id,
          ],
        };
      }

      if (
        this.relationshipDetails.representation == "FIELD" &&
        this.allowedDeleteForFieldRelationship
      ) {
        params.delete_field_data = this.removableFieldsKeys;
      }

      await this.$store.dispatch(
        "entityRelationships/detachEntityRelationshipData",
        params
      );
      if (this.getEntityRelationshipDataDetachStatus) {
        this.$notify.success({
          title: "Success",
          message: "Entity Data detached successfully",
        });
        await this.fetchParentRelatedChildEntityData();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getError || "Error on deleting Entity data",
        });
      }
    },
    async settingsPopUp() {
      // console.log("Settings Enabled");
      this.dialogTableVisible = true;
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([{ name: "region" }]);
      }
      if (node.level > 1) return resolve([]);
    },

    goToEntityData(row) {
      // if (this.isApplicationUserSide) {
      //   let routerData = this.$router.resolve(
      //     `/ap/entity-view/${this.relationshipDetails.relationship_id}?dataId=${row._id}&relationship_id=${this.$route.params.entity_id}&close_tab=true&type=AUfYWb7SC6U=`
      //   );
      //   window.open(routerData.href, "_blank");
      // } else {
      //   let routerData = this.$router.resolve(
      //     `/entity/view/${this.relationshipDetails.relationship_id}?dataId=${row._id}&relationship_id=${this.$route.params.entity_id}`
      //   );
      //   window.open(routerData.href, "_blank");
      // }
      this.relationshipDataId = row._id;
      this.showRelationshipDataDialog = true

    },
    redirectToEntityData(row){
      if (this.isApplicationUserSide) {
        let routerData = this.$router.resolve(
          `/ap/entity-view/${this.relationshipDetails.relationship_id}?dataId=${row._id}&relationship_id=${this.$route.params.entity_id}&close_tab=true&type=AUfYWb7SC6U=`
        );
        window.open(routerData.href, "_blank");
      } else {
        let routerData = this.$router.resolve(
          `/entity/view/${this.relationshipDetails.relationship_id}?dataId=${row._id}&relationship_id=${this.$route.params.entity_id}`
        );
        window.open(routerData.href, "_blank");
      }
    },
    checkAddNewPermission(){
      if(this.relationshipDetails && Object.keys(this.relationshipDetails).includes('permissions')){
        if(this.relationshipDetails.permissions.add == true){
          return true
        }
        return false
      }
      return true
    },
    checkRedirectPermission(){
      if(this.relationshipDetails && Object.keys(this.relationshipDetails).includes('permissions')){
        if(this.relationshipDetails.permissions.redirect == true){
          return true
        }
        return false
      }
      return true
    },
    checkViewPermission(){
      if(this.relationshipDetails && Object.keys(this.relationshipDetails).includes('permissions')){
        if(this.relationshipDetails.permissions.view == true){
          return true
        }
        return false
      }
      return true
    },
    checkAssignPermission(){
      if(this.relationshipDetails && Object.keys(this.relationshipDetails).includes('permissions')){
        if(this.relationshipDetails.permissions.assign == true){
          return true
        }
        return false
      }
      return true
    },
    downloadDocumentsPopup(row, step) {
      this.documentsData =
        row.entityData[step.template_id][step.id].files &&
        row.entityData[step.template_id][step.id].files.length
          ? row.entityData[step.template_id][step.id].files
          : row.entityData[step.template_id][step.id];
      this.downloadDocuments = true;
      //this.downloadDocuments = true
    },
    closeDownloadDocuments() {
      this.downloadDocuments = false;
    },
    async onPreview(data){
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      let newWindow = open(this.getDownloadUrl, "example","width=1500,height=900")
      newWindow.focus();
      newWindow.onload = function() {};
    },
    async onDownloadFile(data) {
      this.downloadLoading = true;
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a"); // Create an anchor tag
          link.href = fileURL; // Set the URL as the href attribute
          link.download = fileName; // Set the desired filename
          document.body.appendChild(link); // Append the anchor tag to the document body
          link.click(); // Simulate a click on the anchor tag to initiate the download
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
        // this.$refs.this.getDownloadUrl.click();
      } else {
        this.downloadLoading = false;
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError,
        });
      }
      this.downloadLoading = false;
    },
    getFieldValue1(field,value) {
      if (field?.inputType == "CURRENCY") {
        let type = field.validations.currency
          ? field.validations.currency
          : "USD";
        let locale = field.validations.locale
          ? field.validations.locale
          : "en-US";
        value = new Intl.NumberFormat(locale, {
          style: "currency",
          currency: type,
        }).format(value);
      } else if (
        field?.inputType == "AGGREGATE_FUNCTION" ||
        field?.inputType == "FORMULA"
      ) {
        let isCurrencyField = (field.selected_fields || []).find((el) => {
          if (
            el?.inputType == "CURRENCY" ||
            el?.input_type == "CURRENCY" ||
            el?.result_type == "CURRENCY"
          ) {
            return true;
          }
        });
        if (isCurrencyField) {
          if (field?.inputType == "AGGREGATE_FUNCTION") {
            let dataTableField = this.getField(
              field.template_id + "#" + isCurrencyField.data_table_key
            );
            if (dataTableField) {
              let rField = (dataTableField.data_table_columns || []).find(
                (e) => e.key == isCurrencyField.key
              );
              if (rField?.validations) {
                isCurrencyField["validations"] = rField.validations;
              }
            }
          } else {
            if (field?.validations) {
              isCurrencyField["validations"] = field.validations;
            }
          }
          let type =
            isCurrencyField &&
            isCurrencyField.validations &&
            isCurrencyField.validations.currency
              ? isCurrencyField.validations.currency
              : "USD";
          let locale =
            isCurrencyField &&
            isCurrencyField.validations &&
            isCurrencyField.validations.locale
              ? isCurrencyField.validations.locale
              : "en-US";
          value = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: type,
          }).format(value);
        }
      }
      return value
    }
  },
  beforeDestroy() {
    // this.$store.commit("entities/setEntityDataById", null, { root: true });
    // this.$store.commit("entities/setEntityDataByEntityId", null, {
    //   root: true,
    // });
    // this.$store.commit("entities/setEntityDataUpdateStatus", null, {
    //   root: true,
    // });
    // this.$store.commit("templatesData/setTemplateDeleteStatus", null, {
    //   root: true,
    // });
    // this.$store.commit("templatesData/setTemplateDeleteError", null, {
    //   root: true,
    // });
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.tableView {
  overflow-wrap: normal;
  word-break: keep-all;
  min-width: max-content;
}
.sidemenu {
  width: 100%;
  overflow-x: auto;
  margin-top: -3%;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  //  direction: rtl;
}
.add-buttons {
  border: 1.5px solid #285ed3;
  box-sizing: border-box;
  border-radius: 2px;
  // padding: 0.45em 0.65em;
  padding: 10px 10px !important;
  margin-left: 10px !important;
  height: 35px;
  color: #285ed3;
  &:hover {
    background-color: #285ed3;
    color: #ffffff;
    border-color: #285ed3;
  }
}
#entity-users-list {
  // width: 90%;
  // display: table;
  margin: 10px 10px;
  .el-dialog__body {
    padding: 20px 20px;
    .el-card {
      .el-card__body {
        padding: 10px;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .inner-navbar {
    margin-top: 10px;
  }
}
.doc {
  min-width: 30px;
  &.selected {
    background: green;
  }
}

.green {
  color: green;
}

.font-17 {
  font-size: 17px;
  cursor: pointer;
}

.documents {
  min-height: 200px;
  max-height: 400px;
  overflow: scroll;
}
</style>
<style lang="scss">
.el-table .cell {
  word-break: break-word;
}
.el-table .td {
  word-wrap: break-word;
}
.pagination-bar {
  .el-pagination {
    .el-pagination__jump {
      @media (max-width: 450px) {
        display: none;
      }
    }
  }
}
</style>
